import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Button, Image, Row, Col } from 'react-bootstrap';
import { Element, scroller } from 'react-scroll'
import Footer from './Footer';
import items from './original_items.json'
import Realisatie from './Realisatie'
import Contact from './Contact';


export default function App() {
  const tags = ['alles', ...new Set(items.map(item => item.tags).flat())];
  const [filter, setFilter] = useState(tags[0])

  const scrollTo = element => scroller.scrollTo(element, {
    duration: 200,
    delay: 0,
    offset: -96,
    smooth: 'easeInOutQuart'
  })

  useEffect(() => {
    const hash = window.location.hash.replace(/^#/, "")
    scrollTo(hash)
  }, [])

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" sticky='top'>
        <Container>
          <Navbar.Brand href="#">
            <img
              alt="Johan Vos Interieur"
              src="/logo.png"
              height="70"
              className="d-inline-block align-top py-3"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link onClick={() => scrollTo('over-ons')} href="#over-ons">
                Over ons
                </Nav.Link> */}
              <Nav.Link onClick={() => scrollTo('realisaties')} href="#realisaties">
                Realisaties
              </Nav.Link>
              <Nav.Link onClick={() => scrollTo('contact')} href="#contact">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Element name="banner">
        <Container fluid className='banner'>
          <Container className='py-5'>
            <div className='py-5'>
              <h1 className='text-light'>Wij ondersteunen jouw project</h1>
              <p>
                Van meubels op maat, tot totale interieur afwerking.
              </p>
              <Button onClick={() => scrollTo('contact')} variant="light" className='text-white'>
                Gratis offerte
              </Button>
            </div>

          </Container>
        </Container>
      </Element>

      {/* <Element name="over-ons">
        <Container fluid>
          <Container className='py-5'>
            <Row>
              <Col lg={8}>
                <h1> Wie wijn we</h1>
                <p>
                  Johan Vos richte Vos-interieur op in xxxx. Wij maken ook keukens alsof ze voor onszelf zouden zijn.
                </p>
              </Col>
              <Col lg={4} className='h-100'>
                <div className='profile-picture'>
                  <Image
                    rounded
                    thumbnail
                    className='profile-picture-bubble'
                    src='bob.jpeg' />
                </div>
              </Col>
            </Row>

          </Container>
        </Container>
      </Element> */}



      <Element name="realisaties">
        <Container fluid>
          <Container className='py-5'>
            <h1>Realisaties</h1>
            {tags.map(tag => (
              <Button
                key={tag}
                variant={tag === filter ? 'dark' : 'outline-dark'}
                className='m-1 btn-pill'
                onClick={() => setFilter(tag)}
              >
                {tag}
              </Button>
            ))}

            <Row >
              {items
                .filter(item => filter === 'alles' ? true : item.tags.includes(filter))
                .map((item, index) => (
                  <Col lg={6} key={index} className='my-3'>
                    <Realisatie
                      item={item}
                    />
                  </Col>
                ))}
            </Row>
          </Container>
        </Container>
      </Element>

      <Element name="contact">
        <Contact />
      </Element>
      
      <footer>
        <Footer />
      </footer>
    </>
  );
}