import { Container, Row, Col, Image } from 'react-bootstrap';
import { FiPhone, FiMail, FiMapPin } from 'react-icons/fi';

export default function Footer() {
  return (
    <Container fluid className='footer'>
      <Container>
        <Row className='py-5'>
          <Col>
            <Image src="vos.png" height={100} className="footer-fox" />
          </Col>
          <Col>
            <div className='d-flex justify-content-start align-items-center mb-3'>
              <FiMapPin className='me-3' />
              Varendonksesteenweg 34A<br />
              2230 Herselt
            </div>
          </Col>
          <Col>
            <div className='d-flex justify-content-start align-items-center mb-3'>
            <a href="tel:+32496531288">
            <FiPhone className='me-3' /> +32 (0)4 96 53 12 88
            </a>
              
            </div>
            <div className='d-flex justify-content-start align-items-center mb-3'>
              <a href="mailto:johan.vos.interieur@gmail.com?subject=Online aanvraag" target="_newtab">
                <FiMail className='me-3' /> Johan.vos.interieur@gmail.com
              </a>

            </div>
          </Col>
        </Row>
        <div className='d-flex justify-content-center text-muted'>
          <p><small>© Johan Vos Interieur 2023</small></p>
        </div>
      </Container>

    </Container>
  );
}