import React, { useState } from 'react';
import { FiTag, FiMapPin } from 'react-icons/fi';
import { Container, Card, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';
import 'swiper/scss/zoom';
import { Navigation, Thumbs } from "swiper";

export default function RealisatieCard(props) {
  const [activeThumb, setActiveThumb] = useState()

  return (
    <Card>
      <Container>
        <h3 className='mx-2 py-3'>{props.item.name}</h3>
        <Swiper
          autoHeight
          loop
          spaceBetween={10}
          navigation
          freeMode
          thumbs={{ swiper: activeThumb }}
          modules={[Navigation, Thumbs]}
          className='product-images-slider'
        >
          {props.item.images.map((image, index) => (
            <SwiperSlide key={index} zoom>
                <Image src={image} rounded layout='fill' objectFit='cover' />
            </SwiperSlide>
          ))}
        </Swiper>
        {props.item.images.length > 1 ? (
          <Swiper
            onSwiper={setActiveThumb}
            loop={false}
            spaceBetween={16}
            slidesPerView={3}
            modules={[Navigation, Thumbs]}
            freeMode={true}
            watchSlidesProgress={true}
            className='product-images-slider-thumbs py-3'
          >
            {props.item.images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className='product-images-slider-thumbs-wrapper'>
                  <Image src={image} rounded />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : <></>}
        {props.item.description}
        <div className='d-flex justify-content-end text-muted'>
          <p className='mx-3'><small><FiMapPin /> {props.item.location}</small></p>
          <p><small><FiTag /> {props.item.tags[0]}</small></p>
        </div>

      </Container>
    </Card>
  )
}
