import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FiPhone, FiMail, FiMapPin } from 'react-icons/fi';
import Form from 'react-bootstrap/Form';
import {useState} from 'react'

export default function Contact() {
  const [name, setName] = useState();
  const [body, setBody] = useState();
  const [tel, setTel] = useState();
  const [mail, setMail] = useState();

  const send = () => window.open(
    "mailto:johan.vos.interieur@gmail.com?subject=" + encodeURI(name+" - online aanvraag")+"&body="+encodeURI(body + "\n\nAanvraag voor: " + name + "\nTel: "+ tel + "\nMail: "+mail),
    '_blank').focus();

  return (
    <Container fluid>
      <Container className='py-5'>
        <h1>Contact</h1>
        <Row>
          <Col lg={6} className='d-flex align-items-center' >
            <div>

              <h3>Vos Interieur</h3>
              <div className='d-flex justify-content-start align-items-center mb-3'>
                <FiMapPin className='me-3' />
                Varendonksesteenweg 34A<br />
                2230 Herselt
              </div>
              <div className='d-flex justify-content-start align-items-center mb-3'>
                <a href="tel:+32496531288">
                  <FiPhone className='me-3' />+32 (0)4 96 53 12 88
                </a>
              </div>
              <div className='d-flex justify-content-start align-items-center mb-3'>
                <a href="mailto:johan.vos.interieur@gmail.com?subject=Online aanvraag" target="_newtab">
                  <FiMail className='me-3' /> johan.vos.interieur@gmail.com
                </a>
              </div>
            </div>

          </Col>

          <Col lg={6}>
            <Card className='p-2'>
              <Container className='p-3'>
                <Form.Group className="mb-3" controlId="naam">
                  <Form.Label>Naam</Form.Label>
                  <Form.Control type="name" onChange={ e => setName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="mail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" onChange={ e => setMail(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="mail">
                  <Form.Label>Telefoon</Form.Label>
                  <Form.Control type="phone" onChange={ e => setTel(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" placeholder="Waar kunnen we je mee helpen?" controlId="body">
                  <Form.Label>Jouw project</Form.Label>
                  <Form.Control as="textarea" rows={5} onChange={ e => setBody(e.target.value)} />
                </Form.Group>
                <Button onClick={send} variant='dark'>Verstuur</Button>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}